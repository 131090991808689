import React, { useCallback } from 'react';
import tw, { styled } from 'twin.macro';

import City from 'types/City';
import useResize from 'hooks/useResize';

import { SlideIcon } from 'components/SlideIcon';
import { useChartAttribute } from 'hooks/useChartAttribute';
import { ChartContainer } from 'components/container/ChartContainer';

import { InfoTitle } from 'components/info/InfoTitle';
import { Loading } from 'components/Loading';
import { PolygonChart } from './PolygonChart';
import { HourlyChart } from './HourlyChart';

const CityNameDiv = styled.div`
  ${tw`
    text-[24px]/[28px]
    lg:text-2xl
    font-bold
  `}
`;

const WeatherDecsriptionDiv = styled.div`
  ${tw`
    text-[16px]/[28px]
    mb-3
    lg:mb-0
    lg:text-base
    font-medium 
    text-slate
  `}
`;

interface HourlyProps {
  city: City;
}
export const Hourly = ({
  city,
}: HourlyProps) => {
  const width = useResize() || 0;
  const { weatherByTimeList = [] } = city;

  const scrollContainerRef = React.useRef<HTMLDivElement>(null);

  const intervalX = 63;

  const scrollLeft = useCallback(() => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollTo({
        left: scrollContainerRef.current.scrollLeft - intervalX * 6,
        behavior: 'smooth',
      });
    }
  }, [scrollContainerRef, width]);

  const scrollRight = useCallback(() => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollTo({
        left: scrollContainerRef.current.scrollLeft + intervalX * 6,
        behavior: 'smooth',
      });
    }
  }, [scrollContainerRef, width]);

  const chartWidth = weatherByTimeList.length * intervalX;
  const chartHeight = 200;
  const { attribute } = useChartAttribute(chartWidth, chartHeight, weatherByTimeList);
  const isFirstOrLast = (index: number) => {
    return !!(index === 0 || index === attribute.length - 1);
  };

  const currentWeather = weatherByTimeList[0] || {
    weatherDecscriptionPrevious1h: '',
    temp: '',
    tempMaxPrevious24h: '',
    tempMinPrevious24h: '',
    windSpeed: '',
    weatherSymbolSrcPrevious1h: './img/weather/sunny.svg',
  };

  return (
    <ChartContainer className="mobile-no-right-padding">
      <Loading isLoading={city.isLoading}>
        <div>
          <div className="w-full lg:flex lg:pt-8 items-start justify-between">
            <div className="lg:w-1/2">
              <CityNameDiv>
                {`${city.name}, ${city.countryName}`}
              </CityNameDiv>
              <WeatherDecsriptionDiv>
                {currentWeather.weatherDecscriptionPrevious1h}
              </WeatherDecsriptionDiv>
              <div className="flex justify-start items-center mb-3 lg:hidden">
                <img
                  alt="currentWeather"
                  className="lg:hidden w-[96px] h-[96px]"
                  src={currentWeather.weatherSymbolSrcPrevious1h}
                />
                <div className="text-[30px] font-bold">
                  {`${currentWeather?.temp}°`}
                </div>
              </div>
              <div className="lg:w-full lg:flex items-center justify-start">
                <div className="hidden lg:block lg:py-5 lg:mr-4 text-5xl font-bold">
                  {`${currentWeather?.temp}°`}
                </div>
                <div className="lg:border-l flex-1 border-slate">
                  <div className="lg:px-2 flex items-center justify-start text-sm mb-2">
                    <div className="w-[84px] mr-4">
                      <span className="text-slate mr-1">최고</span>
                      <span className="font-bold">{`${currentWeather?.tempMaxPrevious24h}°`}</span>
                    </div>
                    <div className="w-[84px] mr-4">
                      <span className="text-slate mr-1">습도</span>
                      <span className="font-bold">{`${'n'}%`}</span>
                    </div>
                    <div className="w-[84px] mr-4">
                      <span className="text-slate mr-1">풍속</span>
                      <span className="font-bold">{`${currentWeather?.windSpeed}m/h`}</span>
                    </div>
                  </div>
                  <div className="lg:px-2 flex items-center justify-start text-sm">
                    <div className="w-[84px] mr-4">
                      <span className="text-slate mr-1">최저</span>
                      <span className="font-bold">{`${currentWeather?.tempMinPrevious24h}°`}</span>
                    </div>
                    <div className="w-[84px] mr-4">
                      <span className="text-slate mr-1">강수확률</span>
                      <span className="font-bold">{`${'n'}%`}</span>
                    </div>
                    <div className="w-[84px] mr-4">
                      <span className="text-slate mr-1">체감온도</span>
                      <span className="font-bold">{`${'n'}°`}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="lg:w-1/2 hidden lg:flex lg:pr-10 items-center justify-end">
              <img
                alt="currentWeather"
                className="w-1/2 h-1/2"
                src={currentWeather.weatherSymbolSrcPrevious1h}
              />
            </div>
          </div>
          <div className="w-full flex">
            <div className="ml-[-16px] lg:ml-0 pr-1 lg:pr-0 h-full">
              <div className="h-[200px] w-[70px] flex items-end justify-center py-5">
                <SlideIcon
                  className="invisible lg:visible"
                  direction="left"
                  onClick={scrollLeft}
                />
              </div>
              <InfoTitle>강수(mm)</InfoTitle>
              <InfoTitle>날씨</InfoTitle>
              <InfoTitle>시간</InfoTitle>
            </div>
            <div
              ref={scrollContainerRef}
              className="w-[700px] overflow-x-auto lg:overflow-x-hidden"
            >
              <PolygonChart
                width={chartWidth}
                height={chartHeight}
                weatherByTimeList={weatherByTimeList}
              />
              <HourlyChart
                weatherByTimeList={weatherByTimeList}
                attribute={attribute}
                isFirstOrLast={isFirstOrLast}
              />
            </div>
            <div className="h-[200px] w-[70px] items-end justify-center py-5 hidden lg:flex">
              <SlideIcon
                className="invisible lg:visible"
                direction="right"
                onClick={scrollRight}
              />
            </div>
          </div>
        </div>
      </Loading>
    </ChartContainer>
  );
};
