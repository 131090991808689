import React, { useCallback, useContext } from 'react';
import tw, { styled } from 'twin.macro';
import { useNavigate } from 'react-router-dom';
import CityListContext from 'hooks/useCityList';

import City from 'types/City';
import { Loading } from 'components/Loading';

interface CardBoxProps {
  url: string;
}

const CardBox = styled.div<CardBoxProps>`
  ${tw`
    flex
    flex-col
    cursor-pointer
    w-full
    h-[180px]
    lg:h-[272px]
    lg:m-0
    justify-end
  `}

  background-repeat: no-repeat;
  background-size: cover;
  background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0.52%,
      rgba(127, 127, 127, 0.4) 50.62%,
      rgba(0, 0, 0, 0.5) 73.02%,
      rgba(0, 0, 0, 0.59) 81.35%
    ),
    url(${(props) => props.url});
  border-radius: 33px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

  &:hover {
    transition: transform 0.2s linear;
    transform: scale(1.03);
  }
`;

const ContentBox = styled.div`
  ${tw`
    text-white
    w-full
    flex
    justify-between
    items-end
    pl-2
    lg:pl-5
    pb-1
    lg:pb-3
    pr-1
    lg:pr-2
  `}

  .content-left {
    ${tw`
      pl-2
      lg:pl-0
      pb-2
      lg:pb-0
    `}

    .temp {
      ${tw`
        font-bold
        text-white
        mb-2
        text-[36px]/[30px]
        lg:text-6xl/[55px]
      `}
    }
  
    .city-name {
      ${tw`
        text-base/[24px]
        font-medium
        text-white
        overflow-hidden
        text-ellipsis
      `}

      word-break: keep-all;
    }
  }

  .city-icon {
    ${tw`
      w-[70px]
      min-w-[70px]
      h-[70px]
      lg:w-[90px]
      lg:h-[90px]
    `}
  }
`;

interface ImageCardProps {
  city: City
}

export const ImageCard = ({ city }: ImageCardProps) => {
  const {
    name, imgSrc, weatherByTimeList = [], isLoading,
  } = city;
  const { temp, weatherSymbolSrcPrevious1h } = weatherByTimeList[0] ?? {};

  const navigate = useNavigate();
  const { addCity } = useContext(CityListContext);

  const moveToDetailPage = useCallback(() => {
    addCity(city);

    navigate({
      pathname: '/detail',
    });
  }, [addCity, city, navigate]);

  return (
    <CardBox url={imgSrc || ''} onClick={moveToDetailPage}>
      <Loading isLoading={isLoading}>
        <ContentBox>
          <div className="content-left overflow-hidden text-ellipsis">
            <p className="temp">{`${Math.round(temp || 0)}°`}</p>
            <p className="city-name">{name}</p>
          </div>
          <div className="content-right">
            <img
              alt="city icon"
              className="city-icon"
              src={weatherSymbolSrcPrevious1h || ''}
            />
          </div>
        </ContentBox>
      </Loading>
    </CardBox>
  );
};
