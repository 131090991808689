import Weather from 'types/Weather';

interface Point {
  x: number;
  y: number;
}

export const useChartAttribute = (
  width: number,
  height: number,
  weatherByTimeList: Weather[],
) => {
  // data를 그래프 좌표로 변환
  const getCordinates = (dataArray: number[]): Point[] => {
    const maxLength = dataArray.length;
    const topMargin = 40;

    // X축에 해당하는 데이터의 수 - 1만큼 나눔
    const intervalX = width / (maxLength - 1);
    // 배열의 최대값을 구함
    const max = Math.max(...dataArray);
    const min = Math.min(...dataArray);
    const range = max - min;

    return dataArray.map((cur, index) => ({
      x: index * intervalX - 30,
      y: ((max - cur) / range) * 140 + topMargin, // TODO: 왜 반대로?
    }));
  };

  // path 그리기
  const getPathLineAttribute = (): string => {
    const cords = getCordinates(
      weatherByTimeList.map((weather) => weather.temp),
    );

    return cords.reduce((acc, cur, index) => {
      const isFirstPoint = index === 0;
      if (isFirstPoint) return `M ${cur.x},${cur.y}`;

      return `${acc} L ${cur.x} ${cur.y}`;
    }, '');
  };

  const getPathFillAttribute = (): string => {
    const cords = getCordinates(
      weatherByTimeList.map((weather) => weather.temp),
    );

    let path = cords.reduce((acc, cur, index) => {
      const isFirstPoint = index === 0;
      return isFirstPoint ? `M ${cur.x},${cur.y}` : `${acc} L ${cur.x},${cur.y}`;
    }, '');

    if (cords.length > 0) {
      const lastPoint = cords[cords.length - 1];
      const firstPoint = cords[0];
      path += ` L ${lastPoint.x},200 L ${firstPoint.x},200 Z`;
    }

    return path;
  };

  // 곡선을 위한 제어포인터 계산
  const getOpposedLine = (pointA: Point, pointB: Point): { length: number; angle: number } => {
    const xLength = pointB.x - pointA.x;
    const yLength = pointB.y - pointA.y;

    const zLength = Math.sqrt((xLength ** 2) + (yLength ** 2));
    const angle = Math.atan2(yLength, xLength);

    return { length: zLength, angle };
  };

  // 제어포인트 획득
  const getControlPoint = (prev: Point | null, cur: Point, next: Point | null, isEndControlPoint: boolean): Point => {
    const prevPoint = prev ?? cur;
    const nextPoint = next ?? cur;

    const smoothDegree = 0.25;

    const o = getOpposedLine(prevPoint, nextPoint); // 계산된 제어 포인트 획득

    const angle = o.angle + (isEndControlPoint ? Math.PI : 0);
    const length = o.length * smoothDegree;

    const x = cur.x + Math.cos(angle) * length;
    const y = cur.y + Math.sin(angle) * length;

    return { x, y };
  };

  const pathLineAttribute = getPathLineAttribute();
  const pathFillAttribute = getPathFillAttribute();
  const attribute = getCordinates(
    weatherByTimeList.map((weather) => weather.temp),
  );

  return { pathLineAttribute, pathFillAttribute, attribute };
};
