import React from 'react';
import tw, { styled } from 'twin.macro';
import { useNavigate } from 'react-router-dom';

import CityListContext from 'hooks/useCityList';
import { SearchInputBox } from 'components/search/SearchInputBox';
import CityListSelector from './CityListSelector';

interface TopContainerDivProps {
  isMultiMode: boolean;
}

const TopContainerDiv = styled.div<TopContainerDivProps>`
  ${tw`
    w-full
    flex
    items-center
    justify-between
    lg:mb-9
    h-[60px]
    lg:h-auto
  `}

  ${(props) => props.isMultiMode && tw`
    mb-6  
    lg:mb-[60px]
  `}

  .logo-box {
    ${tw`
      cursor-pointer
      lg:h-[30px]
      h-[21px]
      lg:pl-6
      pl-4
    `}

    img {
      ${tw`
        h-full
      `}   
    }
  }
`;

export const DetailTop = () => {
  const navigate = useNavigate();
  const { cityList } = React.useContext(CityListContext);

  return (
    <>
      <TopContainerDiv isMultiMode={cityList.length > 1}>
        <div className="logo-box" onClick={() => navigate('/')}>
          <img alt="detail" src="./img/logo-text-black.svg" />
        </div>
        <SearchInputBox
          className="top"
          isUsingMobileSearch
        />
      </TopContainerDiv>
      { cityList.length > 1 && (
        <CityListSelector />
      )}
    </>
  );
};
