import React from 'react';
import tw, { styled } from 'twin.macro';
import { findExtremeTempCities } from 'utils/weather';

import City from 'types/City';

const OverViewWrapper = styled.div`
  ${tw`
    lg:pt-7
    pb-4
    w-full
    lg:flex
    flex-col
    justify-between
  `}
`;

const OverViewTitle = styled.div`
  ${tw`
    text-base
    font-bold
    lg:text-2xl
  `}
`;

const OverViewDesc = styled.div`
  ${tw`
    text-sm
    mr-3
    text-slate
  `}
`;

const OverViewTempBox = styled.div`
  ${tw`
    flex
    items-center
    lg:justify-end
  `}

  .town-city {
    ${tw`
      text-sm
      mr-3
      text-slate
    `}
  }
`;

interface WholeOverViewProps {
  cityList: City[];
}

const MultiOverView = ({
  cityList,
}: WholeOverViewProps) => {
  const firstDateText = cityList[0].weatherByDateList && cityList[0].weatherByDateList[0].date.format('MM월 DD일');
  const lastDateText = cityList[0].weatherByDateList && cityList[0].weatherByDateList[cityList[0].weatherByDateList.length - 1].date.format('MM월 DD일');

  const {
    hottest,
    coldest,
  } = findExtremeTempCities(cityList);

  return (
    <OverViewWrapper>
      <div className="mb-3 lg:mb-0">
        <OverViewTitle>{cityList.length}개 도시 비교</OverViewTitle>
        <OverViewDesc>{firstDateText} - {lastDateText}</OverViewDesc>
      </div>
      <div>
        <OverViewTempBox>
          <div className="town-city">
            {hottest?.city.name}, {hottest?.city.countryName}
          </div>
          <div className="lg:text-xl font-bold text-red">
            {hottest?.temp}°
          </div>
        </OverViewTempBox>
        <OverViewTempBox>
          <div className="town-city">
            {coldest?.city.name}, {coldest?.city.countryName}
          </div>
          <div className="lg:text-xl font-bold text-blue">
            {coldest?.temp}°
          </div>
        </OverViewTempBox>
        <OverViewTempBox>
          <div className="town-city">최대 온도차</div>
          <div className="lg:text-xl font-bold">
            {Math.round(((hottest?.temp || 0) - (coldest?.temp || 0)) * 10) / 10}°
          </div>
        </OverViewTempBox>
      </div>
    </OverViewWrapper>
  );
};

export default MultiOverView;
