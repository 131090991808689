import React, { useState, useCallback, useEffect } from 'react';
import tw, { styled } from 'twin.macro';
import moment from 'moment';
import axios from 'modules/axios';

import City from 'types/City';
import Weather from 'types/Weather';
import { convertSymbolToImgSrc, convertSymbolToDescription } from 'utils/weather';

import { ImageCard } from './ImageCard';

const ImageCardWrapper = styled.div`
  ${tw`
    grid
    xl:grid-rows-2
    lg:grid-cols-4
    lg:max-w-[1000px]
    lg:pt-24
    lg:py-10
    lg:gap-6
    lg:w-[100%]
    md:grid-cols-3
    grid-cols-2
    gap-5
    p-5
    items-center
    justify-center
    m-auto
    transform
    duration-200
    z-0
  `}
`;

const defaultCityList = [
  {
    name: '파리',
    countryName: '프랑스',
    imgSrc: './img/city/paris.png',
    lat: 48.8534951,
    lon: 2.3483915,
    currentWeather: {
      date: moment(),
      temp: 17,
      weatherSymbolSrcPrevious1h: './img/weather/sun.svg',
    },
    isLoading: true,
  },
  {
    name: '런던',
    countryName: '영국',
    imgSrc: './img/city/london.png',
    lat: 51.5073219,
    lon: -0.1276474,
    currentWeather: {
      date: moment(),
      temp: 23,
      weatherSymbolSrcPrevious1h: './img/weather/cloud.svg',
    },
    isLoading: true,
  },
  {
    name: '로마',
    countryName: '이탈리아',
    imgSrc: './img/city/roma.png',
    lat: 41.8933203,
    lon: 12.4829321,
    currentWeather: {
      date: moment(),
      temp: 19,
      weatherSymbolSrcPrevious1h: './img/weather/rain.svg',
    },
    isLoading: true,
  },
  {
    name: '바르셀로나',
    countryName: '스페인',
    imgSrc: './img/city/barcel.png',
    lat: 41.3828939,
    lon: 2.1774322,
    currentWeather: {
      date: moment(),
      temp: 15,
      weatherSymbolSrcPrevious1h: './img/weather/sunny-little-cloud.svg',
    },
    isLoading: true,
  },
];

export const ImageCardArea = () => {
  const [cityList, setCityList] = useState<City[]>(defaultCityList);

  const fetchWeather = useCallback(async (city: City) => {
    if (!city.lat || !city.lon) return [];

    const res = await axios.get('/fetch/weather', {
      params: {
        lat: city.lat,
        lon: city.lon,
        days: 0,
      },
    });

    const formattedWeatherByTimeList = res.data.map((weather: Weather) => ({
      ...weather,
      date: moment(weather.date),
      weatherSymbolSrcPrevious1h: convertSymbolToImgSrc(weather.weatherSymbolIdPrevious1h || 0),
      weatherSymbolSrcPrevious24h: convertSymbolToImgSrc(weather.weatherSymbolIdPrevious24h || 0),
      weatherDecscriptionPrevious1h: convertSymbolToDescription(weather.weatherSymbolIdPrevious1h || 0),
    }));

    return formattedWeatherByTimeList;
  }, []);

  const fetchWholeWeather = useCallback(async () => {
    const promiseList = defaultCityList.map((city: City) => fetchWeather(city));
    const cityCurrentWeatherList = await Promise.all(promiseList);
    setCityList((prevCityList) => prevCityList.map((city: City, index: number) => ({
      ...city,
      weatherByTimeList: cityCurrentWeatherList[index],
      isLoading: false,
    })));
  }, [fetchWeather]);

  useEffect(() => {
    fetchWholeWeather();
  }, [fetchWholeWeather]);

  return (
    <ImageCardWrapper>
      {cityList.map((city: City) => (
        <ImageCard
          key={city.name}
          city={city}
        />
      ))}
    </ImageCardWrapper>
  );
};
