import React from 'react';
import tw, { styled } from 'twin.macro';
import moment from 'moment';
import cs from 'classnames';

import { InfoContent } from 'components/info/InfoContent';

import Weather from 'types/Weather';

const InfoWrapper = styled.div`
  ${tw`
    relative
  `}
`;

const CHART_TYPES = Object.freeze({
  RAIN: 'RAIN',
  SYMBOL: 'SYMBOL',
  TIME: 'TIME',
});

interface HourlyInfoBoxProps {
  weatherByTimeList: Weather[];
  attribute: {
    x: number;
    y: number;
  }[];
  isFirstOrLast: (_index: number) => boolean;
}

export const HourlyChart = ({
  weatherByTimeList, attribute, isFirstOrLast,
}: HourlyInfoBoxProps) => {
  return (
    <div className="w-[700px]">{
      Object.keys(CHART_TYPES).map((key) => (
        <div key={key} className="flex h-[50px]">
          <InfoWrapper>
            {attribute && attribute.map(({ x, y }, index) => {
              if (isFirstOrLast(index)) return null;

              const weather = weatherByTimeList[index];
              const tailwindClassName = weather.precipPrevious1h && weather.precipPrevious1h > 0
                ? 'text-blue'
                : 'text-slate';

              let value;
              switch (key) {
                case CHART_TYPES.RAIN:
                  value = weather.precipPrevious1h;
                  break;
                case CHART_TYPES.SYMBOL:
                  value = weather.weatherSymbolIdPrevious1h;
                  break;
                case CHART_TYPES.TIME:
                  value = `${weather.date.format('HH')}시`;
                  if (weather.date.hour() === 0) {
                    switch (weather.date.date()) {
                      case moment().add(1, 'days').date():
                        value = '내일';
                        break;
                      case moment().add(2, 'days').date():
                        value = '모레';
                        break;
                      case moment().add(3, 'days').date():
                        value = '글피';
                        break;
                      // no default
                    }
                  }
                  break;
                default:
                  break;
              }

              return (
                <InfoContent
                  key={x}
                  style={{
                    position: 'absolute',
                    width: '60px',
                    left: x - 25,
                  }}
                  className={tailwindClassName}
                >
                  {key === CHART_TYPES.SYMBOL
                    ? (
                      <img
                        className="w-[30px] h-[30px]"
                        src={weather.weatherSymbolSrcPrevious1h}
                        alt="기상 아이콘..."
                      />
                    )
                    : (
                      <span
                        className={
                          cs({
                            'text-black': key === CHART_TYPES.TIME,
                            'bg-current rounded-[11px] text-white w-[50px] text-center': key === CHART_TYPES.TIME && weather.date.hour() === 0,
                          })
                        }
                      >
                        {value}
                      </span>
                    )}
                </InfoContent>
              );
            })}
          </InfoWrapper>
        </div>
      ))
    }
    </div>
  );
};
