import { useEffect, useState, useRef } from 'react';

const useDebounce = (value: string, delay: number): [string, boolean] => {
  const [debouncedValue, setDebouncedValue] = useState(value);
  const [isWaitingDebounce, setIsWaitingDebounce] = useState(false);
  const isFirstInput = useRef(true);

  useEffect(() => {
    if (isFirstInput.current && value.length === 1) {
      setDebouncedValue(value);
      setIsWaitingDebounce(false);
      isFirstInput.current = false;
      return () => {};
    }

    setIsWaitingDebounce(true);
    const timer = setTimeout(() => {
      setDebouncedValue(value);
      setIsWaitingDebounce(false);
    }, delay);

    return () => {
      clearTimeout(timer);
    };
  }, [value, delay]);

  useEffect(() => {
    if (value === '') {
      isFirstInput.current = true;
    }
  }, [value]);

  return [debouncedValue, isWaitingDebounce];
};

export default useDebounce;
