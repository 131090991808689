import React from 'react';
import { BiSolidChevronLeft, BiSolidChevronRight } from 'react-icons/bi';
import tw, { styled } from 'twin.macro';

const SlideBox = styled.div`
  ${tw`
    rounded-full
    w-[30px]
    h-[30px]
    flex
    items-center
    justify-center
    cursor-pointer
  `}
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
`;

interface SlideIconProps {
  className?: string;
  direction: string;
  onClick?: () => void;
}

export const SlideIcon = ({
  className,
  direction,
  onClick,
}: SlideIconProps) => {
  return (
    <SlideBox className={className} onClick={onClick}>
      {direction === 'left' ? (
        <BiSolidChevronLeft />
      ) : (
        <BiSolidChevronRight />
      )}
    </SlideBox>
  );
};
