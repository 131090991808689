import React, {
  useCallback, useEffect, useRef, useState, useContext, useMemo,
} from 'react';
import tw, { styled } from 'twin.macro';
import { useNavigate } from 'react-router-dom';
import { FiSearch } from 'react-icons/fi';
import { CgClose } from 'react-icons/cg';
import { useQuery } from 'react-query';
import axios from 'modules/axios';
import useDebounce from 'hooks/useDebounce';
import countries from 'i18n-iso-countries';
import cs from 'classnames';
import { Loading } from 'components/Loading';

import City from 'types/City';

import CityListContext from 'hooks/useCityList';
import useResize from 'hooks/useResize';
import { SearchInput } from './SearchInput';
import { CustomButton } from '../CustomButton';

const registerLocale = async () => {
  const localeKO = await import('i18n-iso-countries/langs/ko.json');
  countries.registerLocale(localeKO);
};
registerLocale();

const SearchInputBoxDiv = styled.div`
  ${tw`
    relative
    sm:w-[280px]
  `}

  &.main {
    ${tw`
      md:min-w-[320px]
      lg:min-w-[540px]
    `}
  }

  &.top {
    ${tw`
      w-auto
      lg:min-w-[540px]
      pr-5
      lg:pr-0
    `}
  }
`;

interface SearchBackgroudDivProps {
  isUsingMobileSearch: boolean;
  isSearchResultOpened: boolean;
}
const SearchBackgroudDiv = styled.div<SearchBackgroudDivProps>`
  ${tw`
    lg:touch-manipulation
    w-full
  `}

  ${(props) => props.isUsingMobileSearch && props.isSearchResultOpened && tw`
    z-[1000]
    fixed
    top-[60px]
    left-0
    lg:static
    h-full
    lg:h-auto
    bg-[#ededed]
    lg:bg-transparent
    px-5
    lg:px-0
  `}
`;

interface SearchInputHolderDivProps {
  isUsingMobileSearch: boolean;
  isSearchResultOpened: boolean;
}
const SearchInputHolderDiv = styled.div<SearchInputHolderDivProps>`
  ${tw`
    flex
    bg-white
    justify-between
    rounded-2xl
    py-[9px]
    pl-[21px]
    pr-2.5

    w-full
  `}

  ${(props) => props.isUsingMobileSearch && !props.isSearchResultOpened && tw`
    hidden
    lg:flex
  `}
`;

interface SearchSubBoxDivProps {
  isUsingMobileSearch: boolean;
}
const SearchSubBoxDiv = styled.div<SearchSubBoxDivProps>`
  ${tw`
    absolute
    top-[60px]
    w-full
    bg-white
    rounded-2xl
    mt-1
    py-[18px]
    px-[28px]
    max-h-[300px]
    overflow-y-auto
    z-10
  `}

  ${(props) => props.isUsingMobileSearch && tw`
    w-[calc(100% - 40px)]
  `}

  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

  .title {
    ${tw`
      text-slate
      lg:text-sm
      xl:text-sm
      text-xs
      mb-3
    `}
  }
`;

const CityListBox = styled.div`
  ${tw`
    flex
    items-center
    my-1
    hover:bg-slate-100
    cursor-pointer
    rounded-lg
  `}

  .search-icon {
    ${tw`
      m-1
      w-[30px]
      h-[30px]
      scale-[0.8]
      lg:scale-[1]
      xl:scale-[1]
    `}
  }
  .city-text {
    ${tw`
      pl-1
      text-sm
      xl:text-base
      lg:text-base
    `}
  }
`;

interface SearchResultProps {
  searchValue: string;
  cityList: City[];
  isUsingMobileSearch: boolean;
  setIsSearchResultOpened: (isSearchResultOpened: boolean) => void;
  isLoading: boolean,
  isWaitingDebounce: boolean,
}
const SearchResult = ({
  searchValue,
  cityList,
  isUsingMobileSearch,
  setIsSearchResultOpened,
  isLoading,
  isWaitingDebounce,
}: SearchResultProps) => {
  const navigate = useNavigate();
  const { addCity, cityHistoryList } = useContext(CityListContext);

  const moveToDetailPage = useCallback((city: City) => {
    addCity(city);
    setIsSearchResultOpened(false);

    navigate({
      pathname: '/detail',
    });
  }, [addCity, setIsSearchResultOpened, navigate]);

  const titleText = useMemo(() => {
    if (searchValue) {
      return null;
    }

    if (cityHistoryList.length) {
      return '도시 검색 기록';
    }

    return '가장 많이 검색된 유럽도시';
  }, [cityHistoryList, searchValue]);

  // if (searchValue?.length < 3
  //   && searchValue?.length > 0
  //   && !cityList.length
  // ) {
  //   return null;
  // }

  return (
    <SearchSubBoxDiv isUsingMobileSearch={isUsingMobileSearch}>
      <Loading isLoading={(searchValue && isWaitingDebounce) || isLoading}>
        {titleText ? (
          <div className="title">{titleText}</div>
        ) : null}
        {cityList.map((city) => (
          <CityListBox
            key={city.name}
            onClick={() => moveToDetailPage(city)}
          >
            <img
              className="search-icon"
              src="./img/search.svg"
              alt="search"
            />
            <div className="city-text">
              {`${city.name}, ${city.countryName}`}
            </div>
          </CityListBox>
        ))}
        {cityList.length === 0 && (
          <div>검색 결과가 없습니다.</div>
        )}
      </Loading>
    </SearchSubBoxDiv>
  );
};

const defaultCityList: Array<City> = [
  {
    name: '파리',
    countryName: '프랑스',
    lat: 48.8534951,
    lon: 2.3483915,
  },
  {
    name: '런던',
    countryName: '영국',
    lat: 51.5073219,
    lon: -0.1276474,
  },
  {
    name: '바르셀로나',
    countryName: '스페인',
    lat: 41.3828939,
    lon: 2.1774322,
  },
  {
    name: '로마',
    countryName: '이탈리아',
    lat: 41.8933203,
    lon: 12.4829321,
  },
  {
    name: '프라하',
    countryName: '체코',
    lat: 49.470333,
    lon: 24.441559,
  },
];

const fetchCity = async (keyword?: string, previousCityList?: City[], cityHistoryList?: City[]) => {
  if (!keyword) {
    if (cityHistoryList?.length) return cityHistoryList;
    return defaultCityList;
  }

  // if (keyword && keyword.length < 3) {
  //   return [];
  // }

  const res = await axios.get('/fetch/city', { params: { keyword } });
  const _cityList = res.data;
  const formattedCityList = _cityList.map((city: City) => {
    const language = 'ko';
    const countryName = city.country ? countries.getName(city.country, language) : null;
    const name = city.local_names
      ? (city.local_names[language] || city.local_names.en || city.local_names[Object.keys(city.local_names)[0]])
      : city.name;
    return {
      ...city,
      name,
      countryName,
    };
  });
  return formattedCityList;
};

const useCitySearchQuery = (keyword?: string, previousCityList?: City[], cityHistoryList?: City[]) => {
  return useQuery(['citySearch', keyword], () => fetchCity(keyword, previousCityList, cityHistoryList), {
    initialData: [],
  });
};

interface SearchInputBoxProps {
  className?: string;
  isUsingMobileSearch?: boolean;
}
export const SearchInputBox = ({ className, isUsingMobileSearch = false }: SearchInputBoxProps) => {
  const { cityList: previousCityList, cityHistoryList } = useContext(CityListContext);
  const [searchValue, setSearchValue] = useState('');
  const [isSearchResultOpened, setIsSearchResultOpened] = useState(false);
  const targetRef = useRef(null);
  const width = useResize();
  const isMobile = width < 768;

  const [debouncedSearchValue, isWaitingDebounce] = useDebounce(searchValue, 300);
  const {
    data: cityList, isFetching: isLoading,
  } = useCitySearchQuery(
    debouncedSearchValue,
    previousCityList,
    cityHistoryList,
  );

  const handleValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setSearchValue(newValue);
  };

  return (
    <SearchInputBoxDiv className={className}>
      {isUsingMobileSearch && (
        <div>
          {isSearchResultOpened
            ? (
              <CgClose
                className="lg:hidden search-icon h-6 w-6"
                onClick={() => setIsSearchResultOpened(false)}
              />
            )
            : (
              <FiSearch
                className="lg:hidden search-icon h-6 w-6"
                onClick={() => setIsSearchResultOpened(true)}
              />
            )}
        </div>
      )}
      <SearchBackgroudDiv
        isUsingMobileSearch={isUsingMobileSearch}
        isSearchResultOpened={isSearchResultOpened}
      >
        <SearchInputHolderDiv
          ref={targetRef}
          isUsingMobileSearch={isUsingMobileSearch}
          isSearchResultOpened={isSearchResultOpened}
        >
          <SearchInput
            value={searchValue}
            onChange={handleValue}
            onFocus={() => setIsSearchResultOpened(true)}
            onBlur={() => {
              if (isUsingMobileSearch && isMobile) return;
              setTimeout(() => setIsSearchResultOpened(false), 250);
            }}
          />

          <div className="hidden lg:block">
            <CustomButton
              onClick={() => fetchCity()}
            >
              검색하기
            </CustomButton>
          </div>
        </SearchInputHolderDiv>
        {isSearchResultOpened && (
          <SearchResult
            searchValue={searchValue}
            cityList={cityList}
            isUsingMobileSearch={isUsingMobileSearch}
            setIsSearchResultOpened={setIsSearchResultOpened}
            isLoading={isLoading}
            isWaitingDebounce={isWaitingDebounce}
          />
        )}
      </SearchBackgroudDiv>
    </SearchInputBoxDiv>
  );
};
