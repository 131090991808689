import React from 'react';
import cs from 'classnames';
import tw, { styled } from 'twin.macro';

const LoadingDiv = styled.div`
  ${tw`
    relative
    h-[48px]
    w-[48px]
  `}

  @keyframes loading-rotate {
    from {
      transform: translateY(0);
      background-color: #d9d9d9;
    }

    16% {
      transform: translateY(-10px);
      background-color: #17c1e8;
    }

    32% {
      transform: translateY(0);
      background-color: #d9d9d9;
    }
  }

  .dot {
    ${tw`
      absolute
      h-2
      w-2
      top-5
      rounded
    `}

    animation: loading-rotate 1800ms ease-out infinite;
    background-color: #d9d9d9;

    &.dot1 {
      left: 6px;
    }

    &.dot2 {
      left: 20px;
      animation-delay: 600ms;
    }

    &.dot3 {
      left: 34px;
      animation-delay: 1200ms;
    }
  }
`;

const LoadingHolderDiv = styled.div`
  ${tw`
    absolute
    w-full
    h-full
    top-0
    z-[100]
    // bg-white
    flex
    justify-center
    items-center
  `}
`;

interface ChildrenHolderDivProps {
  isLoading?: boolean;
}
const ChildrenHolderDiv = styled.div<ChildrenHolderDivProps>`
  &.loading, &.loading * {
    visibility: hidden !important;
  }
`;

interface LoadingProps {
  isLoading?: boolean;
  children?: React.ReactNode;
}

export const Loading = ({
  isLoading = true,
  children,
}: LoadingProps) => {
  const LoadingJSX = (
    <LoadingDiv className="loading">
      <div className="dot dot1" />
      <div className="dot dot2" />
      <div className="dot dot3" />
    </LoadingDiv>
  );

  if (children) {
    return (
      <div
        className="relative"
      >
        {isLoading && (
          <LoadingHolderDiv>
            {LoadingJSX}
          </LoadingHolderDiv>
        )}
        <ChildrenHolderDiv className={cs({ loading: isLoading })}>
          {children}
        </ChildrenHolderDiv>
      </div>
    );
  }

  return (
    LoadingJSX
  );
};
