import React from 'react';
import tw, { styled } from 'twin.macro';

const Button = styled.button`
  ${tw`
    h-[40px]
    px-6
    text-[14px]
    font-medium
    duration-100
    rounded-lg
  `}

  background-color: #3a416f;
  color: white;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.07),
    0px 4px 7px -1px rgba(0, 0, 0, 0.11);

  &:hover {
    border-radius: 10px;
    background: #272b44;
  }
`;

interface CustomButtonProps {
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  children?: React.ReactNode;
}

export const CustomButton = ({
  onClick = () => {},
  children,
}: CustomButtonProps) => {
  return (
    <Button
      onClick={onClick}
    >{children}
    </Button>
  );
};
