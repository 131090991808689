import React from 'react';
import tw, { styled } from 'twin.macro';

const ChartWrapperDiv = styled.div`
  ${tw`
    bg-white
    w-full
    rounded-3xl
    
    mb-6
    p-6
    
    lg:mb-[2.5rem]
    lg:px-[80px]
    lg:pt-2
    lg:pb-8
  `}
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

  &.mobile-no-right-padding {
    ${tw`
      pr-0
    `}
  }
`;

interface ChartContainerProps {
  children: React.ReactNode;
  className?: string;
}

export const ChartContainer = ({ children, className }: ChartContainerProps) => {
  return (
    <ChartWrapperDiv className={className}>
      {children}
    </ChartWrapperDiv>
  );
};
