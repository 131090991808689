import React, { useCallback } from 'react';
import tw, { styled } from 'twin.macro';
import moment from 'moment';
import cs from 'classnames';

import City from 'types/City';
import useResize from 'hooks/useResize';

import { SlideIcon } from 'components/SlideIcon';
import { InfoTitle } from 'components/info/InfoTitle';
import { InfoContent } from 'components/info/InfoContent';
import { ChartContainer } from 'components/container/ChartContainer';
import { Loading } from 'components/Loading';

const DailyContentWrapper = styled.div`
  ${tw`
    relative
    w-[80px]
    lg:w-[140px]
    shrink-0
    rounded-lg
    inline-block
  `}

  .icon-box {
    ${tw`
      w-full
      h-[200px]
      relative
      border-0
      lg:border-l-[1px]
    `}
    border-image: linear-gradient(rgba(183, 183, 183, 0.00) 0%, #B7B7B7 47.92%, rgba(183, 183, 183, 0.00) 100%);
    border-image-slice: 1;
  }

  .info-box {
    ${tw`
      w-full
    `}
  }
`;

const TempBox = styled.div`
  ${tw`
    absolute
    w-[1em]
    z-50
  `}
  left: 50%;
  transform: translate(-50%);
  border-radius: 10px;
  background: linear-gradient(0deg, #ffedac 0%, #fdc500 100%);

  & span {
    position: absolute;
    left: 50%;
    transform: translate(-50%);
  }
`;

const RainBox = styled.div`
  ${tw`
    absolute
    w-[2.5em]
  `}

  left: 50%;
  top: 100%;
  transform: translate(-50%, -100%);
  background: linear-gradient(
    180deg,
    rgba(168, 197, 255, 0.6) 0%,
    rgba(33, 212, 253, 0) 100%
  );
`;

interface DailyProps {
  city: City;
}
export const Daily = ({
  city,
}: DailyProps) => {
  const { weatherByDateList = [] } = city;
  const scrollContainerRef = React.useRef<HTMLDivElement>(null);
  const width = useResize() || 0;

  const scrollLeft = useCallback(() => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollTo({
        left: scrollContainerRef.current.scrollLeft - 140,
        behavior: 'smooth',
      });
    }
  }, [scrollContainerRef, width]);

  const scrollRight = useCallback(() => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollTo({
        left: scrollContainerRef.current.scrollLeft + 140,
        behavior: 'smooth',
      });
    }
  }, [scrollContainerRef, width]);

  const wholeMaxTemp = Math.max(
    ...weatherByDateList
      .map((weather) => weather.tempMaxPrevious24h || 0),
  );
  const wholeMinTemp = Math.min(
    ...weatherByDateList
      .map((weather) => weather.tempMinPrevious24h || 0),
  );
  const wholeTempRange = wholeMaxTemp - wholeMinTemp;

  const wholeMaxPrecip = Math.max(
    ...weatherByDateList
      .map((weather) => weather.precipPrevious24h || 0),
  );

  return (
    <ChartContainer className="mobile-no-right-padding">
      <Loading isLoading={city.isLoading}>
        <div className="lg:text-2xl text-[16px]/[26px] font-bold lg:h-[100px] mb-4 flex items-center">
          일일 날씨
        </div>
        <div className="flex w-full mb-2">
          <div className="h-full ml-[-16px] lg:ml-0 pr-1 lg:pr-0 ">
            <div className="h-[200px] w-[70px] flex items-end justify-center py-5">
              <SlideIcon
                className="invisible lg:visible"
                direction="left"
                onClick={scrollLeft}
              />
            </div>
            <InfoTitle>강수(mm)</InfoTitle>
            <InfoTitle>날씨</InfoTitle>
            <InfoTitle>요일</InfoTitle>
          </div>
          <div
            ref={scrollContainerRef}
            className="w-[700px] flex overflow-x-auto lg:overflow-x-hidden"
          >
            {weatherByDateList && weatherByDateList.map((weather, index) => {
              const maxTemp = weather.tempMaxPrevious24h || 0;
              const minTemp = weather.tempMinPrevious24h || 0;
              const precip = Math.round((weather.precipPrevious24h || 0));
              const isToday = weather.date.subtract(1).date() === moment().date();

              return (
                <DailyContentWrapper className="bg-white">
                  <div
                    className={`icon-box ${
                      index === 0 ? 'border-x' : 'border-r'
                    }`}
                  >
                    <TempBox
                      style={{
                        height: ((maxTemp - minTemp) / wholeTempRange) * 140,
                        top: 30 + ((wholeMaxTemp - maxTemp) / wholeTempRange) * 140,
                      }}
                    >
                      <span className="top-[-26px] text-base font-bold">{maxTemp}°</span>
                      <span className="top-[calc(100%+4px)] text-base font-bold">{minTemp}°</span>
                    </TempBox>
                    <RainBox style={{ height: (precip / wholeMaxPrecip) * 140 }} />
                  </div>
                  <div className="info-box">
                    <InfoContent
                      className={
                      precip > 0 ? 'text-blue' : 'text-slate'
                  }
                    >
                      {precip}
                    </InfoContent>
                    <InfoContent>
                      <img
                        alt={weather.weatherSymbolSrcPrevious1h}
                        className="w-[40px] h-[50px]"
                        src={weather.weatherSymbolSrcPrevious1h}
                      />
                    </InfoContent>
                    <InfoContent>
                      <div className="flex flex-col items-center justify-center">
                        <span className={cs('px-3 py-[1px] rounded-[11px] font-bold', {
                          'bg-current': isToday,
                          'text-white': isToday,
                        })}
                        >{isToday ? '오늘' : weather.date.lang('ko').format('dddd')}
                        </span>
                        <span className="text-slate">{weather.date.format('MM.DD')}</span>
                      </div>
                    </InfoContent>
                  </div>
                </DailyContentWrapper>
              );
            })}
          </div>
          <div className="h-[200px] w-[70px] items-end justify-center py-5 hidden lg:flex">
            <SlideIcon
              className="invisible lg:visible"
              direction="right"
              onClick={scrollRight}
            />
          </div>
        </div>
      </Loading>
    </ChartContainer>
  );
};
