import React from 'react';
import tw, { styled } from 'twin.macro';

import cityListContext from 'hooks/useCityList';
import { DetailTop } from './components/DetailTop';
import { Hourly } from './components/hourly';
import { Daily } from './components/daily';
import { Multi } from './components/multi';
import { MonthlyChart } from './components/montly/MonthlyChart';

const DetailWrapper = styled.div`
  ${tw`
    w-full
    max-w-[1000px]
    xl:w-[1000px]
    m-auto
    lg:py-4
  `}
`;

export const Detail = () => {
  const { selectedCity, cityList } = React.useContext(cityListContext);

  return (
    <DetailWrapper>
      <DetailTop />
      { selectedCity
        ? (
          <>
            <Hourly city={selectedCity} />
            <Daily city={selectedCity} />
            {/* <MonthlyChart /> 임시 비활성화 */}
          </>
        )
        : (
          <Multi cityList={cityList} />
        )}
    </DetailWrapper>
  );
};
