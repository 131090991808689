import React from 'react';
import {
  createBrowserRouter,
  RouterProvider,
} from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';

import 'moment/locale/ko';

import CityListContext, { useCityList } from 'hooks/useCityList';
import { Home } from 'pages/home';
import { Detail } from 'pages/detail';

const queryClient = new QueryClient();

const router = createBrowserRouter([
  {
    path: '/',
    element: <Home />,
  },
  {
    path: '/detail',
    element: <Detail />,
  },
]);

const App = () => {
  const data = useCityList();

  return (
    <div className="App w-full h-full overflow-auto bg-[#ededed]">
      <QueryClientProvider client={queryClient}>
        <CityListContext.Provider value={data}>
          <RouterProvider router={router} />
        </CityListContext.Provider>
      </QueryClientProvider>
    </div>
  );
};

export default App;
