import React, { useCallback } from 'react';
import cs from 'classnames';
import moment from 'moment';
import tw, { styled } from 'twin.macro';

import City from 'types/City';
import useResize from 'hooks/useResize';

import { SlideIcon } from 'components/SlideIcon';
import { Loading } from 'components/Loading';

const CityWeatherListContainerDiv = styled.div`
${tw`
  flex
  border-t
  border-slate-line
`}
`;

const CityInfoContainerDiv = styled.div`
  ${tw`
    w-[84px]
    min-w-[84px]
    lg:h-[130px]
    h-[90px]
    text-sm
    flex
    flex-1
    flex-col
    justify-center
    items-end
    pr-2
  `}
`;

const DateDiv = styled.div`
  ${tw`
    w-[92px]
    min-w-[92px]
    lg:w-[122px]
    lg:min-w-[122px]
    flex
    flex-col
    justify-center
    items-center
    pt-3
    text-sm
  `}
`;

const WeatherListDiv = styled.div`
  ${tw`
    w-full
    lg:h-[130px]
    h-[90px]
    flex
    items-center
    border-slate-line
  `}
`;

const WeatherDiv = styled.div`
  ${tw`
    w-[92px]
    min-w-[92px]
    lg:w-[122px]
    lg:min-w-[122px]
    h-full
    flex
    flex-col
    justify-between
    lg:px-3
    lg:py-4
  `}

  .icon-box {
    ${tw`
      flex
      justify-center
      items-center
    `}

    img {
      ${tw`
        w-[40px]
        h-[40px]
        lg:w-[56px]
        lg:h-[56px]
      `}
    }
  }

  .temp-box {
    ${tw`
      flex
      flex-col
      lg:flex-row
      w-full
      justify-center
      items-center
      lg:gap-x-2
    `}

    .temp {
      ${tw`
        lg:text-base
        text-xs
        font-semibold
      `}
    }

    .slash {
      ${tw`
        hidden
        lg:block
        text-sm
        text-slate
      `}
    }
  }

  .rain-percent {
    ${tw`
      flex
      justify-center
    `}

    & div {
      ${tw`
        text-xs
      `}
    }
  }
`;

interface CityWeatherListProps {
  cityList: City[];
}

const CityWeatherList = ({ cityList }: CityWeatherListProps) => {
  const scrollContainerRef = React.useRef<HTMLDivElement>(null);
  const width = useResize();

  const scrollLeft = useCallback(() => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollTo({
        left: scrollContainerRef.current.scrollLeft - 244,
        behavior: 'smooth',
      });
    }
  }, [scrollContainerRef, width]);

  const scrollRight = useCallback(() => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollTo({
        left: scrollContainerRef.current.scrollLeft + 244,
        behavior: 'smooth',
      });
    }
  }, [scrollContainerRef, width]);

  return (
    <CityWeatherListContainerDiv>
      <div className="flex flex-col justify-end">
        {cityList.map((city) => {
          return (
            <div className="flex">
              <CityInfoContainerDiv>
                <div className="font-bold">{city.name}</div>
                <div className="text-slate">{city.countryName}</div>
              </CityInfoContainerDiv>
            </div>
          );
        })}
      </div>
      <div className="min-w-[72px] pl-[6px] pr-[36px] items-center justify-center self-stretch hidden lg:flex">
        <SlideIcon
          className="invisible lg:visible"
          direction="left"
          onClick={scrollLeft}
        />
      </div>
      <div
        ref={scrollContainerRef}
        className="overflow-x-auto lg:overflow-x-hidden w-full"
      >
        <div className="flex flex-row">
          {(cityList[0].weatherByDateList || []).map((weather) => {
            const date = weather.date.subtract(1);
            const isToday = date.date() === moment().date();

            return (
              <DateDiv>
                <div className={cs(
                  'px-3 py-[1px] rounded-[11px] font-bold',
                  {
                    'bg-current': isToday,
                    'text-white': isToday,
                  },
                )}
                >
                  {isToday ? '오늘' : date.lang('ko').format('dddd')}
                </div>
                <div className="text-slate">
                  {date.format('MM.D')}
                </div>
              </DateDiv>
            );
          })}
        </div>
        {cityList.map((city, index) => {
          const { weatherByDateList = [] } = city;
          const isFirst = index === 0;

          return (
            <Loading isLoading={city.isLoading}>
              <div className="flex-col w-fit">
                <WeatherListDiv className={cs({ 'lg:border-t': !isFirst })}>
                  {weatherByDateList.map((weather) => {
                    const precip = Math.round((weather.precipPrevious24h || 0));

                    return (
                      <WeatherDiv>
                        <div className="icon-box">
                          <img
                            alt="city weather"
                            src={weather.weatherSymbolSrcPrevious24h}
                          />
                        </div>
                        <div className="temp-box">
                          <div className="temp">
                            {weather.tempMaxPrevious24h || '-'}°
                          </div>
                          <div className="slash">/</div>
                          <div className="temp">
                            {weather.tempMinPrevious24h || '-'}°
                          </div>
                        </div>
                        <div className="rain-percent">
                          <div className={cs(
                            'text-sm font-bold',
                            precip > 0 ? 'text-blue' : 'text-slate',
                          )}
                          >
                            {precip ? `${precip}mm` : '0'}
                          </div>
                        </div>
                      </WeatherDiv>
                    );
                  })}
                </WeatherListDiv>
              </div>
            </Loading>
          );
        })}
      </div>
      <div className="min-w-[72px] pl-[36px] pr-[6px] items-center justify-center self-stretch hidden lg:flex">
        <SlideIcon
          className="invisible lg:visible"
          direction="right"
          onClick={scrollRight}
        />
      </div>
    </CityWeatherListContainerDiv>
  );
};

export default CityWeatherList;
