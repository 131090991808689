import React from 'react';

import tw, { styled } from 'twin.macro';
import { SearchArea } from './components/SearchArea';
import { ImageCardArea } from './components/imagecard/ImageCardArea';

const MainWrapper = styled.div`
  ${tw`
    w-screen
    h-screen
  `}
`;

// const WaveSvg = styled.svg`
//   fill: #ffffff;
//   height: 150px;
// `;

export const Home = () => {
  return (
    <MainWrapper>
      <SearchArea />
      {/* <WaveSvg className="wave-border" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 100" preserveAspectRatio="none">
        <path d="M0,50 C150,100 350,0 500,50 C650,100 850,0 1000,50 L1000,100 L0,100 Z" />
      </WaveSvg> */}
      <ImageCardArea />
    </MainWrapper>
  );
};
