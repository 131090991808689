import React from 'react';

import City from 'types/City';

import { ChartContainer } from 'components/container/ChartContainer';
import MultiOverView from './MultiOverView';
import CityWeatherList from './CityWeatherList';

interface MultiProps {
  cityList: City[];
}

export const Multi = ({
  cityList,
}: MultiProps) => {
  return (
    <ChartContainer>
      <MultiOverView cityList={cityList} />
      <CityWeatherList cityList={cityList} />
    </ChartContainer>
  );
};
