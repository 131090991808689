import React from 'react';
import tw, { styled } from 'twin.macro';
import { SearchInputBox } from 'components/search/SearchInputBox';
import useResize from 'hooks/useResize';

const SearchBoxDiv = styled.div`
  ${tw`
    w-full
    h-full
    m-auto
    flex
    items-center
    justify-center
    flex-col
  `}

  & > * {
    ${tw`
      flex
      items-center
      justify-center
    `}
  }

  .logo-image {
    ${tw`
      min-w-[150px]
      h-[30px]
      mb-14
      sm:mb-[30px]
    `}
  }

  .main-title {
    ${tw`
      font-bold
      mb-8
      text-white
      lg:mb-14
      text-2xl
      [line-height: 40px]
      text-center
      md:text-[1.5rem]
      lg:text-4xl/[60px]
      xl:text-5xl/[60px]
    `}
  }
`;
const SearchBox = () => {
  const width = useResize() || 0;
  return (
    <SearchBoxDiv>
      <img className="logo-image" src="./img/logo-text-white.svg" alt="logo-text-white" />
      <div className="main-title">
        가장 정확한 날씨, {width < 768 && <br />}검색하여 확인하세요.
      </div>
      <SearchInputBox className="main" />
    </SearchBoxDiv>
  );
};

const SearchAreaDiv = styled.div`
  ${tw`
    relative
    w-full
    h-[calc(100% - 460px)]
    lg:h-[50%]
    px-4

    transform
    duration-200
    z-10
  `}

  background-image: url('./img/main-back.png');
  background-repeat: no-repeat;
  background-size: cover;
`;
export const SearchArea = () => {
  return (
    <SearchAreaDiv>
      <SearchBox />
    </SearchAreaDiv>
  );
};
