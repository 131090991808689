import React, { CSSProperties } from 'react';
import tw, { styled } from 'twin.macro';

const ContentBox = styled.div`
  ${tw`
    flex
    h-[50px]
    justify-center
    items-center
    py-2
    text-sm
  `}
`;

interface InfoContentProps {
  children: React.ReactNode;
  style?: CSSProperties,
  className?: string,
}

export const InfoContent = ({ children, style, className }: InfoContentProps) => {
  return <ContentBox style={style} className={className}>{children}</ContentBox>;
};
