import React from 'react';
import tw, { styled } from 'twin.macro';

const Title = styled.div`
  ${tw`
    w-[70px]
    h-[50px]
    py-2
    flex
    items-center
    justify-end
    text-slate
    text-sm
  `}
`;

interface InfoTitleProps {
  children: React.ReactNode;
}

export const InfoTitle = ({ children }: InfoTitleProps) => {
  return <Title>{children}</Title>;
};
