import React, { useState, useEffect } from 'react';
import cs from 'classnames';
import tw, { styled } from 'twin.macro';
import {
  DragDropContext, Droppable, Draggable, DropResult,
} from '@hello-pangea/dnd';

import CityListContext from 'hooks/useCityList';

const CityListSelectorContainerDiv = styled.div`
  ${tw`
    w-full
    flex
    items-center
    mb-7
    pl-5
    pr-5
    lg:pl-0
    overflow-x-auto
  `}
`;

const CityTagDroppableContainerDiv = styled.div`
  ${tw`
    flex
    justify-start
    gap-4
  `}
`;

interface CityTagDivProps {
  isSelected: boolean;
  hasRemoveButton: boolean;
}
const CityTagDiv = styled.div<CityTagDivProps>`
  ${tw`
    select-none
    inline-flex
    h-[34px]
    items-center
    gap-2.5
    shrink-0
    pl-5
    pr-[5px]
    py-[5px]
    rounded-[31px]
    border
    border-[#2152ff]
    bg-white
    text-center
    text-xs
    font-bold
    leading-3
    tracking-[-0.48px]
    text-[#2152ff]
    cursor-pointer
  `}

  ${(props) => props.isSelected && tw`
    border-none
    bg-[linear-gradient(135deg,#21D4FD_0%,#2152FF_100%)]
    text-white
  `}

  ${(props) => !props.hasRemoveButton && tw`
    pr-5
  `}
`;

const CityListSelector = () => {
  const {
    cityList, moveCity, selectCity, selectedCity, removeCity,
  } = React.useContext(CityListContext);

  const onDragStart = (result: any) => {
  };

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }
    moveCity(result.source.index, result.destination.index);
  };

  /* eslint-disable react/jsx-props-no-spreading */
  return (
    <CityListSelectorContainerDiv>
      <CityTagDiv
        onClick={() => selectCity(null)}
        isSelected={selectedCity === null}
        hasRemoveButton={false}
      >
        전체 도시 보기
      </CityTagDiv>

      <svg
        width="2"
        height="20"
        viewBox="0 0 2 20"
        className="mx-4 flex-shrink-0"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="2" height="20" fill="#979797" />
      </svg>

      <DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable" direction="horizontal">
          {(provided, snapshot) => (
            <CityTagDroppableContainerDiv
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {cityList.map((city, index) => (
                <Draggable
                  key={city.id}
                  draggableId={city.id || ''}
                  index={index}
                >
                  {(provided2, snapshot2) => {
                    const isSelected = (selectedCity && selectedCity.id) === city.id;
                    const hasRemoveButton = (selectedCity && selectedCity.id) !== city.id;
                    return (
                      <CityTagDiv
                        ref={provided2.innerRef}
                        {...provided2.draggableProps}
                        {...provided2.dragHandleProps}
                        onClick={() => selectCity(city.id || null)}
                        isSelected={isSelected}
                        hasRemoveButton={hasRemoveButton}
                      >
                        {city.name}
                        {hasRemoveButton && (
                          <svg
                            onClick={() => removeCity(city)}
                            xmlns="http://www.w3.org/2000/svg"
                            width="25"
                            height="26"
                            viewBox="0 0 25 26"
                            fill="none"
                          >
                            <circle cx="12.5" cy="13" r="12.5" fill="#D9D9D9" />
                            <line x1="7.35355" y1="8.14645" x2="17.3536" y2="18.1464" stroke="black" />
                            <line x1="7.89645" y1="18.1464" x2="17.8964" y2="8.14645" stroke="black" />
                          </svg>
                        )}
                      </CityTagDiv>
                    );
                  }}
                </Draggable>
              ))}
              {provided.placeholder}
            </CityTagDroppableContainerDiv>
          )}
        </Droppable>
      </DragDropContext>
    </CityListSelectorContainerDiv>
  );
  /* eslint-enable react/jsx-props-no-spreading */
};

export default CityListSelector;
