import React, { useState } from 'react';
import tw, { styled } from 'twin.macro';
import { BiSearch } from 'react-icons/bi';

const SearchInputDiv = styled.div`
  ${tw`
    flex-1
    bg-white
    flex
    flex-col
    items-start
  `}

  .search-icon {
    ${tw`
      w-6
      h-6
      mr-1
    `}
  }
`;

const InputWrapper = styled.div`
  ${tw`
    flex
    p-2
    items-center
    w-full
  `}
`;

const Input = styled.input`
  ${tw`
    outline-none
    text-sm
    w-full
  `}
`;

const ErrorMessage = styled.p`
  ${tw`
    text-red-500
    text-xs
    mt-1
  `}
`;

interface SearchInputProps {
  className?: string;
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onFocus: (event: React.FocusEvent<HTMLInputElement>) => void;
  onBlur: (event: React.FocusEvent<HTMLInputElement>) => void;
}

export const SearchInput = ({
  className,
  value,
  onChange,
  onFocus,
  onBlur,
}: SearchInputProps) => {
  const [error, setError] = useState<string | null>(null);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    onChange(event);

    // if (newValue.length > 0 && newValue.length < 3) {
    //   setError('3글자 이상 입력해 주세요... (2글자 검색 지원 예정)');
    // } else {
    //   setError(null);
    // }
  };

  return (
    <SearchInputDiv className={className}>
      <InputWrapper>
        <label htmlFor="searchInput">
          <BiSearch className="search-icon" />
        </label>
        <Input
          type="text"
          placeholder="서울, 런던, 뉴욕, 도쿄"
          id="searchInput"
          value={value}
          onChange={handleInputChange}
          onFocus={onFocus}
          onBlur={onBlur}
        />
      </InputWrapper>
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </SearchInputDiv>
  );
};
