import City from 'types/City';

export const convertSymbolToImgSrc = (symbol: number) => {
  switch (symbol) {
    case 1:
      return './img/weather/sun.svg';
    case 101:
      return './img/weather/moon.svg';

    case 2:
      return './img/weather/sun-little-cloud.svg';
    case 102:
      return './img/weather/moon-little-cloud.svg';

    case 3:
      return './img/weather/sun-many-cloud.svg';
    case 103:
      return './img/weather/moon-many-cloud.svg';

    case 4:
      return './img/weather/cloud.svg';
    case 104:
      return './img/weather/cloud.svg';

    case 5:
      return './img/weather/rain.svg';
    case 105:
      return './img/weather/rain.svg';

    case 6:
      return './img/weather/snow-rain.svg';
    case 106:
      return './img/weather/snow-rain.svg';

    case 7:
      return './img/weather/snow.svg';
    case 107:
      return './img/weather/snow.svg';

    case 8:
      return './img/weather/rain-sun.svg';
    case 108:
      return './img/weather/rain.svg';

    case 9:
      return './img/weather/snow.svg';
    case 109:
      return './img/weather/snow.svg';

    case 10:
      return './img/weather/snow-rain.svg';
    case 110:
      return './img/weather/snow-rain.svg';

    case 11:
      return './img/weather/sun-little-cloud.svg';
    case 111:
      return './img/weather/moon-little-cloud.svg';

    case 12:
      return './img/weather/cloud.svg';
    case 112:
      return './img/weather/cloud.svg';

    case 13:
      return './img/weather/snow.svg';
    case 113:
      return './img/weather/snow.svg';

    case 14:
      return './img/weather/rain.svg';
    case 114:
      return './img/weather/rain.svg';

    case 15:
      return './img/weather/rain.svg';
    case 115:
      return './img/weather/rain.svg';

    case 16:
      return './img/weather/sandstorm.svg';
    case 116:
      return './img/weather/sandstorm.svg';

    default:
      return '';
  }
};

export const convertSymbolToDescription = (symbol: number) => {
  const weatherSymbolDescriptionDict = {
    0: '날씨 코드를 결정할 수 없음',
    1: '맑은 하늘',
    101: '맑은 하늘',
    2: '가벼운 구름',
    102: '가벼운 구름',
    3: '부분적으로 흐림',
    103: '부분적으로 흐림',
    4: '흐림',
    104: '흐림',
    5: '비',
    105: '비',
    6: '비와 눈 / 진눈깨비',
    106: '비와 눈 / 진눈깨비',
    7: '눈',
    107: '눈',
    8: '소나기',
    108: '소나기',
    9: '눈보라',
    109: '눈보라',
    10: '진눈깨비 소나기',
    110: '진눈깨비 소나기',
    11: '약한 안개',
    111: '약한 안개',
    12: '짙은 안개',
    112: '짙은 안개',
    13: '얼어붙는 비',
    113: '얼어붙는 비',
    14: '천둥폭풍',
    114: '천둥폭풍',
    15: '이슬비',
    115: '이슬비',
    16: '모래폭풍',
    116: '모래폭풍',
  };

  return weatherSymbolDescriptionDict[symbol as keyof typeof weatherSymbolDescriptionDict] || '';
};

export const findExtremeTempCities = (cityList: City[]): {
  hottest: { city: City; temp: number } | null,
  coldest: { city: City; temp: number } | null
} => {
  return cityList.reduce((extremes, city) => {
    let { hottest } = extremes;
    let { coldest } = extremes;

    city.weatherByDateList?.forEach((weather) => {
      if (weather.tempMaxPrevious24h !== undefined && (hottest === null || weather.tempMaxPrevious24h > hottest.temp)) {
        hottest = { city, temp: weather.tempMaxPrevious24h };
      }
      if (weather.tempMinPrevious24h !== undefined && (coldest === null || weather.tempMinPrevious24h < coldest.temp)) {
        coldest = { city, temp: weather.tempMinPrevious24h };
      }
    });

    return { hottest, coldest };
  }, {
    hottest: { city: {} as City, temp: -300 },
    coldest: { city: {} as City, temp: 300 },
  });
};
