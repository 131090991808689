import React from 'react';

import { useChartAttribute } from 'hooks/useChartAttribute';
import Weather from 'types/Weather';

interface PolygonChartProps {
  width: number;
  height: number;
  weatherByTimeList: Weather[];
}

export const PolygonChart = ({ width, height, weatherByTimeList }: PolygonChartProps) => {
  const { pathLineAttribute, pathFillAttribute, attribute } = useChartAttribute(width, height, weatherByTimeList);
  const isFirstOrLast = (index: number) => {
    return !!(index === 0 || index === attribute.length - 1);
  };
  return (
    <svg
      width={width}
      height={height}
      xmlns="http://www.w3.org/2000/svg"
    >
      {attribute && attribute.map(({ x, y }, index: number) => {
        if (isFirstOrLast(index)) return null;
        return (
          <text
            x={x - 10}
            y={y - 15}
            fontSize={16}
            fontWeight={600}
            key={`${x}/${y}`}
            className="fill-current"
          >
            {weatherByTimeList[index].temp}°
          </text>
        );
      })}
      {/* 그래프 영역 */}
      <defs>
        <linearGradient
          id="polygon_gradient"
          x1="0%"
          y1="0%"
          x2="0%"
          y2="100%"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFE8BC" />
          <stop offset="1" stopColor="#FFE8BC" stopOpacity="0" />
        </linearGradient>
      </defs>
      <path
        d={pathLineAttribute}
        fill="none"
        stroke="#FFE03F"
        strokeWidth={1}
        xmlns="http://www.w3.org/2000/svg"
      />
      <path
        d={pathFillAttribute}
        fill="url(#polygon_gradient)"
        xmlns="http://www.w3.org/2000/svg"
      />
      {/* <polygon
        points={`${0} ${height} ${attribute} ${width} ${height}`}
        fill="url(#polygon_gradient)"
      /> */}
      {/* 그래프 영역 */}
    </svg>
  );
};
