import React, {
  createContext, useState, useCallback, useMemo, useEffect,
} from 'react';

import moment from 'moment';
import axios from 'modules/axios';
import City from 'types/City';
import Weather from 'types/Weather';
import { convertSymbolToImgSrc, convertSymbolToDescription } from 'utils/weather';
// import defaultCityList from './defaultCityList';

export const useCityList = () => {
  const [cityList, setCityList] = useState<Array<City>>([]);
  const [cityHistoryList, setCityHistoryList] = useState<Array<City>>([]);
  const [currentCityId, setCurrentCityId] = useState<string | null>(null);

  const selectedCity = useMemo(() => {
    return cityList.find((city) => city.id === currentCityId) || null;
  }, [cityList, currentCityId]);

  const selectCity = useCallback((cityId: string | null) => {
    const city = cityList.find((c) => c.id === cityId);
    if (city) {
      setCurrentCityId(cityId);
    } else {
      setCurrentCityId(null);
    }
  }, [cityList]);

  const fetchWeather = useCallback((city: City) => {
    axios
      .get('/fetch/weather', {
        params: {
          lat: city.lat,
          lon: city.lon,
        },
      })
      .then((res) => {
        const formattedWeatherByTimeList = res.data
          .map((weather: Weather) => ({
            ...weather,
            date: moment(weather.date),
            weatherSymbolSrcPrevious1h: convertSymbolToImgSrc(weather.weatherSymbolIdPrevious1h || 0),
            weatherSymbolSrcPrevious24h: convertSymbolToImgSrc(weather.weatherSymbolIdPrevious24h || 0),
            weatherDecscriptionPrevious1h: convertSymbolToDescription(weather.weatherSymbolIdPrevious1h || 0),
          }));

        const weatherByDateList = formattedWeatherByTimeList.filter((weather: Weather) => {
          // 오늘을 제외한 자정만 필터링
          return weather.date.date() !== moment().date() && weather.date.hour() === 0;
        });

        setCityList(
          (prevCityList) => prevCityList
            .map((c) => (c.id === city.id
              ? {
                ...c,
                weatherByTimeList: formattedWeatherByTimeList,
                weatherByDateList,
                isLoading: false,
              }
              : c)),
        );
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  const addCity = useCallback((city: City) => {
    const id = city.id || Math.random().toString(36).substring(2, 15);
    const newCity = { ...city, id, isLoading: true };

    let duplicateCity: City | undefined;
    setCityList((prevCityList) => {
      const newCityList = [...prevCityList];

      duplicateCity = newCityList.find((c) => Math.abs(Math.abs(c.lat) - Math.abs(newCity.lat)) < 0.05
        && Math.abs(Math.abs(c.lon) - Math.abs(newCity.lon)) < 0.05);
      if (duplicateCity) return newCityList;

      newCityList.push(newCity);
      return newCityList;
    });

    if (duplicateCity) {
      setCurrentCityId(duplicateCity.id || null);
    } else {
      setCurrentCityId(newCity.id);
      fetchWeather(newCity);
    }

    setCityHistoryList((prevCityList) => {
      const newCityList = [...prevCityList];

      duplicateCity = newCityList.find((c) => Math.abs(Math.abs(c.lat) - Math.abs(newCity.lat)) < 0.05
        && Math.abs(Math.abs(c.lon) - Math.abs(newCity.lon)) < 0.05);
      if (duplicateCity) return newCityList;

      newCityList.unshift(newCity);
      return newCityList;
    });
  }, [fetchWeather]);

  const removeCity = useCallback((city: City) => {
    setCityList((prevCityList) => prevCityList.filter((c) => c.id !== city.id));
  }, []);

  const moveCity = useCallback((currentIndex: number, targetIndex: number) => {
    setCityList((prevCityList) => {
      const newCityList = [...prevCityList];
      const [removedCity] = newCityList.splice(currentIndex, 1);
      newCityList.splice(targetIndex, 0, removedCity);
      return newCityList;
    });
  }, []);

  const clearCityList = useCallback(() => {
    setCityList([]);
  }, []);

  useEffect(() => {
    if (!cityList.length && location.pathname !== '/') {
      location.href = '/';
      return;
    }

    if (cityList.length === 1) {
      selectCity(cityList[0].id || null);
    }
  }, [cityList]);

  return {
    cityList,
    selectedCity,

    cityHistoryList,

    addCity,
    removeCity,
    moveCity,
    selectCity,
    clearCityList,
  };
};

// 타입 추론을 위해 기본값을 정의
// TODO: useCityList 내의 값을 자동으로 인식할 수 있도록 하는 더 좋은 방법은 없을까?
/* eslint-disable no-unused-vars */
const defaultValue = {
  cityList: [] as City[],
  selectedCity: {} as City | null,

  cityHistoryList: [] as City[],

  addCity: (city: City) => {},
  removeCity: (city: City) => {},
  moveCity: (currentIndex: number, targetIndex: number) => {},
  selectCity: (cityId: string | null) => {},
  clearCityList: () => {},
};
/* eslint-enable no-unused-vars */

const CityListContext = createContext(defaultValue);

export default CityListContext;
